<template>
  <div style="padding: 20px;">
    <div v-if="customdetails == false && customadd == false">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="77px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="客户名称:">
                    <el-input
                      clearable
                      v-model="where.channel_name"
                      placeholder="请输入客户名称"/>
                  </el-form-item>
                </el-col>
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="认证状态:">-->
                <!--    <el-select-->
                <!--        clearable-->
                <!--        v-model="where.approve_status"-->
                <!--        placeholder="请选择状态"-->
                <!--        class="ele-fluid">-->
                <!--      <el-option-->
                <!--          v-for="(item) in dict.type.certificationStatus"-->
                <!--          :label="item.label"-->
                <!--          :value="item.value"-->
                <!--      />-->
                <!--    </el-select>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <el-col :lg="6" :md="12">
                  <el-form-item label="启用状态:">
                    <el-select
                      clearable
                      v-model="where.status"
                      placeholder="请选择状态"
                      class="ele-fluid">
                      <el-option
                          v-for="(item) in dict.type.customerStatus"
                          :label="item.label"
                          :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="custom" style="margin-left: 20px">
                <el-button class="custom-button" @click="openEdit(null)" v-auths="[`${$config.uniquePrefix}custom:list:save`]">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">创建客户</span>
                </el-button>
                <!--<el-button class="custom-button">-->
                <!--  &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                <!--  <i class="el-icon-devops" />-->
                <!--  <span style="vertical-align: middle">批量导入</span>-->
                <!--</el-button>-->
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <el-button @click="ListSetup()">列表设置</el-button>
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <!--自定义列显示 示例-->
              <template v-slot:status= "{ scope }">
                <div >
                  <img style="cursor: pointer" src="@/assets/images/home/yiqiyong.png" alt="" v-if="scope.row.status == 0" >
                  <img style="cursor: pointer" src="@/assets/images/home/weiqiyong.png" alt="" v-else>
                </div>
              </template>

              <template v-slot:approve_status= "{ scope }">
                <div v-if="scope.row.approve_status == 0">
                <span style="
                    width: 8px;
                    height: 8px;
                    background: #ff9e1c;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                 "></span>
                  <span>待认证</span>
                </div>
                <div v-if="scope.row.approve_status == 1">
                <span style="
                    width: 8px;
                    height: 8px;
                    background: #25eb61;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                 "></span>
                  <span>已认证</span>
                </div>
                <div v-if="scope.row.approve_status == 2">
                <span style="
                    width: 8px;
                    height: 8px;
                    background: #ff9e1c;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                 "></span>
                  <span>未通过</span>
                </div>
              </template>


              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" v-auths="[`${$config.uniquePrefix}custom:list:info`]" @click="Todetails(scope.row)">详情</el-link>
                <el-link :underline="false" type="warning" v-auths="[`${$config.uniquePrefix}custom:list:put`]" @click="openEdit(scope.row)">编辑</el-link>
                <el-link slot="reference" :underline="false" type="danger" v-auths="[`${$config.uniquePrefix}custom:list:status`]" v-if="scope.row.status === 0" @click="Enable(scope.row)">停用</el-link>
                <el-link slot="reference" :underline="false" type="danger" v-auths="[`${$config.uniquePrefix}custom:list:status`]" v-if="scope.row.status === 1" style="margin-right: 10px;" @click="Disable(scope.row)">启用</el-link>
                <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
                  <el-link slot="reference" style="margin-left: 10px;" :underline="false" v-auths="[`${$config.uniquePrefix}custom:list:delete`]">删除</el-link>
                </el-popconfirm>
              </template>

            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!--客户编辑-->
    <custom-add v-if="customadd === true" :data="customaddshuju" :id="pid" @showAdd="showadd" />

    <!-- 客户详情模块 -->
    <custom-details v-if="customdetails === true" :xianshi="xianshi" :dict="dict" :data="Editdata" :id="pid" @showDetalis="ShowDetalis"></custom-details>

  </div>
</template>

<script>
// 引入订单详情组件
import CustomDetails from '../details/index.vue'
//引入客户编辑组件
import CustomAdd from '../add/index.vue'

// 引入自定义表格组件
import MyTable from "@/components/MyTable"
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  getfacilitatorApi,
  set_statusApi,
  Delclient
} from '@/api/custom'
import Vue from "vue";

  export default {
    // 组件生效
    components: {
      MyTable,
      PackageSortElement,
      CustomDetails,
      CustomAdd
    },
    data(){
      return{
        // 表格搜索条件
        where: {
          page: 1, // 当前页数
          limit: 10 // 每页显示条目个数
        },
        // 表格数据
        List: [],
        // 总条目数
        total: 0,
        //加载动画
        loading:true,
        // 表头数据
        columns: [
          {
            type: "selection",
            isShow: true
          },
          {
            label: "系统编号",
            // type: "index",
            // width: "70"
            prop: "id",
            isShow: true
          },
          {
            label: "客户编号",
            prop: "client_code",
            isShow: true
          },
          {
            label: "客户名称",
            prop: "channel_name",
            isShow: true
          },
          {
            label: "所在地区",
            prop: "registration_place",
            isShow: true
          },
          {
            label: "客户级别",
            prop: "rank",
            isShow: true
          },
          {
            label: "启用状态",
            prop: "status",
            slot: "status",
            // 权限判断
            auths: ShowTable([`${this.$config.uniquePrefix}custom:set_status`]),
            isShow: true
          },
          // {
          //   label: "认证状态",
          //   prop: "approve_status",
          //   slot: "approve_status",
          //   isShow: true
          // },
          {
            label: "操作",
            slot: "operationSlot",
            isShow: true
          },
        ],

        // 列表设置弹窗
        ListSetupShow:false,

        //详情模块
        Editdata:null,
        customdetails:false,

        //编辑模块
        customaddshuju:null,
        customadd:false,

        //字典
        dict: {
          type:{}
        },

        pid:0,

        xianshi:0,

      }
    },

    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {
      console.log(this.$route.query);
      // console.log(this.$route.query.isDetail)
      //判断是否是从渠道客户列表跳转的详情
      if(this.$route.query.isDetail != undefined && this.$route.query.isDetail == 'true'){
        this.customdetails = true;
      }else {
        this.customdetails = false;
      }

      // 服务项目配置跳转过来的
      if(this.$route.query.id!= undefined){
        getfacilitatorApi(this.where).then(async (res) => {
          this.List = res.data.list;
          this.List.forEach(item => {
            if(item.id == this.$route.query.id){
              this.Editdata = item;
              this.pid = item.id;
              this.xianshi = 1;
            }
          })
          this.customdetails = false;
          this.$nextTick(() => {
            this.customdetails = true;
          })
        }).catch((res) => {
          this.$Message.error(res.msg);
        })
      }else {
        this.customdetails = false;
      }

      // 初始化dict
      this.$store.getters.dict.forEach(item => {
        if (item.name === '客户字典'){
          item.children.forEach(res => {
            Vue.set(this.dict.type, res.code, res.withChildren);
          })
        }
      });
      console.log(this.dict)

      //调用获取客户信息列表方法
      this.getList();
    },

    methods:{
      // 获取列表
      getList(){
        getfacilitatorApi(this.where).then(async (res) => {
          this.total = res.data.count;
          this.List = res.data.list;
          this.loading = false;
        }).catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
        })
      },

      // 查询条件
      QueryList(){
        this.getList();
      },
      // 清空
      empty(){
        this.where = {
          page: 1, // 当前页数
          limit: 10 // 每页显示条目个数
        }
        this.getList();
      },

      // 当前页数改变事件
      currentChange(val) {
        console.log("当前页数", val)
        this.where.page = val;
        this.getList();
      },
      // 当用户手动勾选数据行的 Checkbox 时触发的事件
      select(selection, row) {
        console.log(selection)
        console.log("选择的所有数据行", selection)
        console.log("当前选择的数据行", row)
      },
      ChangeSize(val){
        console.log('当前页面显示的条数',val);
        this.where.limit = val;
        this.getList()
      },

      // 表头行的 className 的回调方法
      headerRowClassName({ row, rowIndex }) {
        return "header_row_class_name"
      },
      // 表头单元格的 style 的回调方法
      headerCellStyle({ row, column, rowIndex, columnIndex }) {
        return "background: #FAFAFA"
      },
      // 行的 className 的回调方法
      rowClassName({ row, rowIndex }) {
        return "row_class_name"
      },
      // 单元格的 style 的回调方法
      cellStyle({ row, column, rowIndex, columnIndex }) {
        if (rowIndex == 1 && columnIndex == 3) {
          // return {
          //   background: "skyBlue",
          //   "border-radius": "20px"
          // }
        }
      },
      // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
      selectable(row, index) {
        if (row.sex == "女") {
          return false
        } else {
          return true
        }
      },

      // 启用状态
      Enable(row){
        let that = this;
        this.$utils.confirm("确定执行此操作吗？", function () {
          // 执行一些需要的逻辑
          // 参数
          let params = {
            status: '1'
          }
          set_statusApi(row.id,params).then(res => {
            if(res.code === 200){
              that.$message.success(res.msg);
              that.getList();
            }else {
              that.$message.error(res.msg)
            }
          }).catch(err => {
            that.$message.error(err.msg);
          })
        }, "确定", 'warning');
      },
      Disable(row){
        let that = this;
        this.$utils.confirm("确定执行此操作吗？", function () {
          // 执行一些需要的逻辑
          // 参数
          let params = {
            status: '0'
          }
          set_statusApi(row.id,params).then(res => {
            if(res.code === 200){
              that.$message.success(res.msg);
              that.getList();
            }else {
              that.$message.error(res.msg)
            }
          }).catch(err => {
            that.$message.error(err.msg);
          })
        }, "确定", 'warning');
      },

      // 删除
      del(row){
        // console.log(row);
        Delclient(row.id).then(res => {
          console.log(res)
          if(res.code === 200){
            this.$message.success(res.msg);
            this.getList();
          }
        }).catch(e => {
          this.$message.error(e.msg)
        })
      },

      // 点击列表设置
      ListSetup(){
        this.ListSetupShow = true;
      },
      // 列表设置关闭
      handleClose(){
        this.ListSetupShow = false;
      },
      //事件  拿到从组件内部处理的数据顺序并赋值
      SortList(value){
        // 接收子组件传递的值替换当前列表显示顺序
        this.columns = value;
      },
      dialogshow(value){
        this.ListSetupShow = value;
      },

      // 接收 详情子组件传递的值
      ShowDetalis(value){
        console.log(value)
        // 隐藏详情子组件
        this.customdetails = false;
        this.getList();
      },
      // 跳转详情
      Todetails(row){
        this.pid = row.id;
        this.Editdata = row;
        //显示详情模块
        this.customdetails = true;
      },

      //点击编辑按钮
      openEdit(row){
        if(row === null){
          this.customaddshuju = row;
          this.customadd = true;
        }else {
          this.customaddshuju = row;
          this.pid = row.id;
          this.customadd = true;
        }
      },
      //接收编辑子组件传递的数据
      showadd(value){
        this.customadd = value;
        this.getList();
      },

    }

  }
</script>

<style lang="scss" scoped>

</style>
